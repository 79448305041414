import React, {Component} from 'react';

class ScoreComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
    const question = this.props.question || {};
    const teamOneScore = this.props.teamOneScore;
    const teamTwoScore = this.props.teamTwoScore;
    return (
        <div className="container">
            <div className="row" style={{justifyContent: "space-between", flexWrap: "nowrap"}}>
                <div style={{width: "45%"}}>
                    <img style={{width: "100%"}} src={question.teamOneImage} alt=""/>
                </div>
                <div style={{width: "45%"}}>
                    <img style={{width: "100%"}} src={question.teamTwoImage} alt=""/>
                </div>
            </div>
            <div className="row" style={{marginTop:10, flexWrap: "nowrap", justifyContent: "space-between"}}>
                <div style={{textAlign:"center", width: "45%"}}>
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            'input[type="text"]:disabled {',
                            'color: white !important;',
                            '}'
                        ].join('\n')
                    }}/>
                    <input
                        value={teamOneScore}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        type="text"
                        placeholder="0"
                        className="scoreGuessStyles"
                        style={{color:this.props.questionColor, width: "100%", fontSize:"5rem",fontWeight:700, padding:0}}
                        onChange={(e)=> {
                            if(e.target.value && e.target.value.length > 3){
                                e.target.value = e.target.value.substring(0,3);
                            }
                            this.props.handleChange(e)
                        }}
                        step="1"
                        autoComplete="off"
                        name="scoreTeamOne"
                        readOnly={this.props.questionAnswered}
                    />
                </div>
                <div style={{textAlign:"center", width: "45%"}}>
                    <input
                        value={teamTwoScore}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        type="text"
                        step="1"
                        autoComplete="off"
                        placeholder="0"
                        className="scoreGuessStyles"
                        style={{color:this.props.questionColor, width: "100%", fontSize:"5rem",fontWeight:700, padding:0}}
                        onChange={(e)=> {
                            if(e.target.value && e.target.value.length > 3){
                                e.target.value = e.target.value.substring(0,3);
                            }
                            this.props.handleChange(e)
                        }}
                        name="scoreTeamTwo"
                        readOnly={this.props.questionAnswered}
                    />
                </div>
            </div>
        </div>
      )
    }
}

export default ScoreComponent;
