import React, { Component } from 'react';
import { PulseLoader as LoadingSpinner } from 'react-spinners'

class Loading extends Component {

  render() {
      const backgroundImage = this.props.backgroundImage;
      if (this.props.loading === true) {
          return (
              <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
                  <div className="loader">
                      <LoadingSpinner color='#fff' loading={true} />
                  </div>
              </div>
          )
      }
  }
}

export default Loading;
