import React, {Component} from 'react'
import PropTypes from 'prop-types'

import '../../styles/css/ProgressButton.css'


const propTypes = {
    backColor: PropTypes.string,
    progressColor: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    progress: PropTypes.number,
}

const defaultProps = {
    backColor: '#000',
    progressColor: '#cc172c',
    textColor: 'white',
}


class ProgressButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
      let container = document.getElementById('progress-button-container')
      let progressWidth = '100'
      // var answersizeVertical = '40px'
      let answersSize = '40px';
      let percentSize = '60px';
      // if(this.props.variables.answersizeVertical && this.props.variables.answersizeVertical.trim().length > 0){
      //   answersizeVertical = this.props.variables.answersizeVertical + "px"
      // }
      if(this.props.variables.answersSize && this.props.variables.answersSize.trim().length > 0){
          answersSize = this.props.variables.answersSize + "px"
      }
        if(this.props.variables.percentSize && this.props.variables.percentSize.trim().length > 0){
            percentSize = this.props.variables.percentSize + "px"
        }

      if(container){
          progressWidth = parseInt(container.clientWidth * this.props.progress / 100, 10)
      }

        return (
            <div className="progress-button-container" id="progress-button-container" style={{
              backgroundColor: this.props.variables.progressBarBackgroundColor
            }}>
                <div className="progress-container" style={{
                    backgroundColor: this.props.correct ? this.props.variables.progressBarEndWinColor : this.props.variables.progressBarColor,
                    width: progressWidth,
                }} />
               <div className="progress-title-container">
                    <p className="progress-title" style={{ color: this.props.variables.progressBarTextColor, fontSize: answersSize, width: '80%', textAlign:'left' }}>{this.props.title}</p>
                    <p className="progress-value" style={{ color: this.props.variables.progressBarTextColor, fontSize: percentSize, width: '20%', textAlign:'right',fontWeight:'bolder' }}>{parseInt(this.props.progress, 10)}%</p>
                </div>
            </div>
        )
    }
}

ProgressButton.propTypes = propTypes
ProgressButton.defaultProps = defaultProps

export default ProgressButton
